/* @ts-expect-error unknown */
import mixpanelPlugin from '@analytics/mixpanel';
import Analytics from 'analytics';
const MIXPANEL_TOKEN = process.env.MIXPANEL_TOKEN;
var AnalyticsEvents;
(function (AnalyticsEvents) {
    AnalyticsEvents["SignIn"] = "Sign-in";
    AnalyticsEvents["SignUp"] = "Sign Up";
})(AnalyticsEvents || (AnalyticsEvents = {}));
const getUserProviderData = (user) => {
    const providerData = {
        providerId: null,
        providerIds: []
    };
    if (user?.providerData?.length > 0) {
        const [firstProvider, ...restProviders] = user.providerData;
        providerData.providerId = firstProvider.providerId;
        providerData.providerIds = [
            firstProvider.providerId,
            ...restProviders.map((provider) => provider.providerId)
        ];
    }
    return providerData;
};
export const _analytics = Analytics({
    app: 'kodekloud-v3',
    plugins: [
        mixpanelPlugin({
            token: MIXPANEL_TOKEN
        })
    ],
    debug: true
});
const analyticsCore = {
    user: null,
    setUser: (user) => {
        analyticsCore.user = user;
    },
    _prepareEmail: (email) => {
        return {
            email: email ?? '',
            Email: email ?? '',
            $email: email ?? '',
            $distinct_id: email ?? ''
        };
    },
    identify: async (fireBaseId, user) => {
        if (!user)
            return;
        const email = (user?.$email ?? user?.Email ?? user?.email ?? '').toString().toLowerCase();
        if (!_analytics || !email)
            return;
        /* @ts-expect-error unknown */
        const mp = window.mixpanel;
        const userData = {
            ...user,
            $email: email,
            $distinct_id: email
        };
        //Set user data to Mixpanel
        mp.people.set(userData);
        //Identify user with email as identifier
        await _analytics.identify(email);
        if (mp?.alias) {
            //TODO: We can delete old Firebase ID identifier after some time,
            // when most of users log in with email maybe in 1 year?
            mp.alias(fireBaseId, email);
        }
    },
    track: (event, data = {}) => {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve) => {
            const callback = () => {
                resolve();
            };
            if (!_analytics) {
                return resolve();
            }
            const email = analyticsCore.user?.email?.toString() ?? data?.email?.toString() ?? '';
            try {
                data = {
                    ...data,
                    ...analyticsCore._prepareEmail(email),
                    v3: true
                };
                await _analytics.track(event, data, null, callback);
                resolve();
            }
            catch (e) {
                resolve();
                window?.console?.log('error', e);
            }
        });
    }
};
async function initLoggedInUser(credentialsUser) {
    const email = credentialsUser?.email ?? '';
    const userData = {
        $email: email,
        $distinct_id: email,
        name: credentialsUser?.displayName ?? email
    };
    if (credentialsUser?.uid) {
        await analyticsCore.identify(credentialsUser?.uid, userData);
    }
    return userData;
}
export async function trackSignIn(credentialsUser) {
    if (!credentialsUser)
        return;
    analyticsCore.setUser(credentialsUser);
    const userData = await initLoggedInUser(credentialsUser);
    await analyticsCore.track(AnalyticsEvents.SignIn, {
        ...userData,
        ...getUserProviderData(credentialsUser)
    });
}
export async function trackSignUp(user) {
    if (!user)
        return;
    analyticsCore.setUser(user);
    const name = user.displayName ?? user.email;
    const creationTime = user.metadata?.creationTime;
    const userData = {
        id: user.uid,
        userEmail: user.email,
        photoUrl: user.photoURL,
        creationTime,
        lastSignInTime: user.metadata?.lastSignInTime,
        isAnonymous: user.isAnonymous,
        name,
        Name: name,
        Email: user.email,
        $email: user.email,
        $distinct_id: user.email,
        'User Registered': creationTime,
        'User ID': user.uid,
        'Registration Context': '',
        ...(user?.providerData?.[0] ?? {}),
        ...getUserProviderData(user)
    };
    if (user?.uid) {
        await analyticsCore.identify(user?.uid, userData);
        await analyticsCore.track(AnalyticsEvents.SignUp, userData);
    }
}
