<script>
	export let label;
	export let disabled = false;
</script>

<button
	style={disabled ? 'opacity: 0.4;' : ''}
	class="w-full inline-flex items-center justify-center rounded-full font-semibold transition focus:outline-offset-2 disabled:cursor-not-allowed bg-gradient-button enabled:hover:bg-gradient-button-darker enabled:active:bg-gradient-button-dark border border-opacity-0 text-white disabled:opacity-50 space-x-2 px-4 py-2.5 text-sm"
	type="submit"
	{disabled}
	on:click
>
	{label}
</button>

<style>
	@tailwind components;
	@tailwind utilities;

	@layer utilities {
		.bg-gradient-button {
			background-image: linear-gradient(0deg, rgb(0 0 0 / 7%), rgb(0 0 0 / 7%)),
				linear-gradient(81.93deg, #1ea7f5 0%, #29ddff 100%);
		}

		.bg-gradient-button-darker {
			background-image: linear-gradient(0deg, rgb(0 0 0 / 16%), rgba(0 0 0 / 16%)),
				linear-gradient(81.93deg, #1ea7f5 0%, #29ddff 100%);
		}

		.bg-gradient-button-dark {
			background-image: linear-gradient(0deg, rgb(0 0 0 / 24%), rgb(0 0 0 / 24%)),
				linear-gradient(81.93deg, #1ea7f5 0%, #29ddff 100%);
		}
	}
</style>
