import { FacebookAuthProvider, GithubAuthProvider, GoogleAuthProvider, OAuthProvider, createUserWithEmailAndPassword, fetchSignInMethodsForEmail, sendEmailVerification, signInWithPopup, signOut, updateProfile } from 'firebase/auth';
import { auth } from './init.js';
export var OAuthProviderNames;
(function (OAuthProviderNames) {
    OAuthProviderNames["Password"] = "password";
    OAuthProviderNames["Google"] = "google.com";
    OAuthProviderNames["Github"] = "github.com";
    OAuthProviderNames["Facebook"] = "facebook.com";
    OAuthProviderNames["Microsoft"] = "microsoft.com";
    OAuthProviderNames["Linkedin"] = "linkedin.com";
    OAuthProviderNames["Enterprise"] = "enterprise";
})(OAuthProviderNames || (OAuthProviderNames = {}));
class AuthService {
    getSignedInUser() {
        return auth.currentUser;
    }
    async oAuthSignInUsingPopup(providerName) {
        const provider = this.getOAuthProvider(providerName);
        return await signInWithPopup(auth, provider);
    }
    createUserWithEmailAndPassword(email, password) {
        return createUserWithEmailAndPassword(auth, email, password);
    }
    fetchSignInMethodsForEmail(email) {
        return fetchSignInMethodsForEmail(auth, email);
    }
    sendEmailVerification(actionCodeSettings) {
        if (!auth.currentUser)
            return;
        return sendEmailVerification(auth.currentUser, actionCodeSettings);
    }
    signOut() {
        return signOut(auth);
    }
    updateProfile(user, { displayName, photoURL }) {
        return updateProfile(user, {
            displayName,
            photoURL
        });
    }
    getOAuthProvider(providerName) {
        switch (providerName) {
            case OAuthProviderNames.Google:
                return new GoogleAuthProvider();
            case OAuthProviderNames.Github:
                return new GithubAuthProvider();
            case OAuthProviderNames.Facebook:
                return new FacebookAuthProvider();
            case OAuthProviderNames.Linkedin:
                return new OAuthProvider('linkedin.com');
            case OAuthProviderNames.Microsoft:
                return new OAuthProvider('microsoft.com');
            default:
                throw new Error(`Unsupported authentication provider: ${providerName}`);
        }
    }
}
const authService = new AuthService();
export default authService;
