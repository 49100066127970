<script>
	// @ts-nocheck
	import { onMount } from 'svelte';
	/* import z from 'zod'; */
	import Button from './Button.svelte';
	import EmailInput from './EmailInput.svelte';
	import NameInput from './NameInput.svelte';
	import PasswordInput from './PasswordInput.svelte';

	export let showLoading = false;
	export let turnstileToken = '';
	export let createAccountUsingEmailAndPassword = () => null;
	export let handleTurnstileToken = () => null;

	let name = '';
	let email = '';
	let password = '';

	$: isNameInvalid = name.trim().length === 0;
	$: isEmailInvalid = !isValidEmail(email);
	$: isPasswordInvalid = password.trim().length === 0;

	/* const schema = z.object({
		email: z.string().email(),
		password: z.string().min(3, 'Password must be 3 or more characters')
	}); */

	function handleNameChange(e) {
		name = e.target.value;
	}

	function handleEmailChange(e) {
		email = e.target.value;
	}

	function handlePasswordChange(e) {
		password = e.target.value;
	}

	function isValidEmail(email) {
		if(email.trim().length === 0){
			return false;
		}
		const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple regex for email validation
		return emailPattern.test(email);
	}

	onMount(() => {
		handleTurnstileToken();
	});
</script>

<form
	id="email-provider-form"
	class="flex flex-col gap-4 mt-4 items-center justify-center w-full"
	on:submit|preventDefault={createAccountUsingEmailAndPassword}
>
	<NameInput on:keyup={handleNameChange} on:input={handleNameChange} />
	<EmailInput on:keyup={handleEmailChange} on:input={handleEmailChange}  />
	<PasswordInput on:keyup={handlePasswordChange} on:input={handlePasswordChange} />
	<div id="turnstile-container" />
	<div class="w-full">
		<Button
			label="Create account"
			disabled={showLoading ||
				!turnstileToken ||
				isNameInvalid ||
				isEmailInvalid ||
				isPasswordInvalid}
		/>
	</div>
</form>
