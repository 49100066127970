/* eslint-disable @typescript-eslint/no-unused-expressions */
/**
 * @param {string | null} message
 * @param {'success'|'error'} mode
 */
export function showSnackbar(message, mode, timeout = 5000, onRemove = () => {}) {
	// Create the wrapper element
	const wrapper = document.createElement('div');
	wrapper.style.backgroundColor = '#fff';
	wrapper.style.borderRadius = '2px';
	wrapper.style.position = 'fixed';
	wrapper.style.zIndex = '99999';
	wrapper.style.left = '50%';
	wrapper.style.top = '20px';
	wrapper.style.transform = 'translateX(-50%)';
	wrapper.style.boxShadow = '0px 2px 10px rgba(0, 0, 0, 0.3)';
	wrapper.style.opacity = '0';
	wrapper.style.transition = 'opacity 0.5s ease, top 0.5s ease';

	// Create the snackbar element
	const snackbar = document.createElement('div');
	snackbar.textContent = message;

	// Set the common inline styles for the snackbar
	snackbar.style.minWidth = '250px';
	snackbar.style.textAlign = 'center';
	snackbar.style.borderRadius = '2px';
	snackbar.style.padding = '16px';
	snackbar.style.fontSize = '17px';

	// Set the background color based on the mode
	if (mode === 'success') {
		snackbar.style.backgroundColor = 'rgba(22, 163, 74, 0.2)'; // Green
		snackbar.style.borderLeft = '3px solid rgb(22, 163, 74)';
	} else if (mode === 'error') {
		snackbar.style.backgroundColor = 'rgba(225, 29, 72, 0.2)'; // Red
		snackbar.style.borderLeft = '3px solid rgb(225, 29, 72)';
	} else {
		snackbar.style.backgroundColor = '#333'; // Default
	}

	// Append the snackbar to the wrapper
	wrapper.appendChild(snackbar);

	// Append the wrapper to the body
	document.body.appendChild(wrapper);

	// Force a reflow to ensure the transition works
	wrapper.offsetHeight;

	// Show the snackbar
	wrapper.style.opacity = '1';
	wrapper.style.top = '40px';

	// Hide and remove the snackbar after the specified timeout
	setTimeout(() => {
		wrapper.style.opacity = '0';
		wrapper.style.top = '20px';
		setTimeout(() => {
			if (wrapper && 'remove' in wrapper) {
				wrapper.remove();
				// Call the onRemove handler if provided
				if (typeof onRemove === 'function') {
					onRemove();
				}
			}
		}, 500);
	}, timeout);
}

// @ts-expect-error ignore
window.showSnackbar = showSnackbar;
