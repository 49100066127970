<svelte:options
        customElement={{
		tag: 'kk-auth-widget',
		shadow: 'none'
	}}
/>

<script>
  // @ts-nocheck

  import {AuthErrorCode, AuthErrorMessageMap, CookieName, TURNSTILE_SCRIPT_URL} from './constants';
  import Form from './Form.svelte';
  import Loader from './Loader.svelte';
  import SocialSignInButton from './SocialSignInButton.svelte';
  import TailwindCss from './TailwindCss.svelte';
  /* @ts-expect-error unknown */
  import {onMount} from 'svelte';
  import {trackSignIn, trackSignUp} from './analytics/index';
  import {AuthService, OAuthProviderNames} from './firebase/index';
  import {
    ADMIN_SDK_SERVICE_ENDPOINT,
    checkAuthStatus,
    createSecureUserSession,
    generateCustomToken
  } from './session-management/index';
  import {showSnackbar} from './snackbarUtil.js';

  export let onSuccessCallback = '';
  let currentProvider = '';
  let username = '';
  let turnstileToken = '';

  let showLoading = false;
  let turnstileLoaded = false;
  let turnstileInitialized = false;
  let authCheckPromise = null;
  let authCheckInProgress = true;
  let authenticatedUser = null;
  let verificationInProgress = false;

  let turnstileCheckTimeout; // Add a variable to hold the timeout reference

  function setKKCookie(cookieName, cookieValue, daysToExpire) {
    const dayInMillis = 24 * 60 * 60 * 1000;  // Convert to milliseconds
    const maxAge = (typeof daysToExpire === 'number' && daysToExpire > 0)
      ? daysToExpire * dayInMillis
      : 7 * dayInMillis;  // Default to 7 days (in milliseconds)

    const d = new Date();
    d.setTime(d.getTime() + maxAge);
    const expires = "expires=" + d.toUTCString();

    document.cookie = `${cookieName}=${cookieValue}; ${expires}; path=/; domain=.kodekloud.com; secure; samesite=lax`;

    // If the cookie is WidgetSignedIn, set the WidgetSignupPage cookie to contain the signup page URL
    if (cookieName === CookieName.WidgetSignedIn) {
      const signupPage = window.location.origin + window.location.pathname
      setKKCookie(CookieName.WidgetSignupPage, signupPage, daysToExpire);
    }
  }

  function hideSignupForm() {
    const signupBlock = document.querySelector('.v3__signup_block'); //wrapper for signup form
    if (signupBlock) {
      signupBlock.style.display = 'none';
    } else {
      const tags = document.getElementsByTagName('kk-auth-widget');
      //hide all instances of the widget
      for (let i = 0; i < tags.length; i++) {
        tags[i].style.display = 'none';
      }
    }
    removeTurnstileScript(); // Remove Turnstile script when hiding the form
  }


  function handleTurnstileToken() {
    // Check if Turnstile is loaded before proceeding
    if (!turnstileLoaded) {
      turnstileCheckTimeout = setTimeout(handleTurnstileToken, 300); // Retry after 300ms
      return;
    }

    if (turnstileLoaded && !turnstileInitialized) {
      // Clear the timeout if Turnstile is loaded
      clearTimeout(turnstileCheckTimeout);

      try {
        window.turnstile.render('#turnstile-container', {
          sitekey: process.env.TURNSTILE_SITE_KEY,
          callback: function (token) {
            turnstileToken = token;
          },
          theme: 'light'
        });
        turnstileInitialized = true;
      } catch (e) {
        //let's not throw any errors here for the turnstile if it fails, it will be retried anyways
      }
    }
  }

  // Function to remove the script
  function removeTurnstileScript() {
    const script = document.getElementById('turnstileScript');
    if (script) {
      script.parentNode.removeChild(script);
      console.log('Turnstile script removed.');
    } else {
      console.log('Turnstile script not found.');
    }
  }

  onMount(() => {
    authCheckPromise = checkAuthStatus()
      .then((user) => {
        if (user) {
          authenticatedUser = user.email;
          setKKCookie(CookieName.LoggedIn, 1, 7);
          setKKCookie(CookieName.WidgetSignedIn, authenticatedUser, 7);
          hideSignupForm();
        }
      })
      .finally(() => {
        authCheckInProgress = false;
      });

    // Set a timeout to stop checking after 30 seconds
    turnstileCheckTimeout = setTimeout(() => {
      console.log('Stopped checking for Turnstile after 30 seconds.');
      turnstileLoaded = true; // Optionally set this to true to stop further checks
    }, 30000); // 30 seconds

    function onloadTurnstileCallback() {
      turnstileLoaded = true;
    };
    window.onloadTurnstileCallback = onloadTurnstileCallback;

    function injectTurnstileScript() {
      const existingScript = document.querySelector(`script[src="${TURNSTILE_SCRIPT_URL}"]`);
      if (existingScript) {
        console.log('Turnstile script is already injected.');
        onloadTurnstileCallback(); // Call the callback if the script is already present
        return;
      }
      var script = document.createElement('script');
      script.src = TURNSTILE_SCRIPT_URL;
      script.async = true;
      script.defer = true;
      script.id = 'turnstileScript';
      script.onload = onloadTurnstileCallback; // Call the callback when the script loads
      document.head.appendChild(script);
    }

    // Call the function to inject the script
    injectTurnstileScript();
  });

  function showAccountCreatedSuccessMessage(createCookies = true) {
    const data = {
      username,
      provider: currentProvider,
      email: authenticatedUser
    };
    showSnackbar('Account created successfully', 'success', 5000, () => {
      if (createCookies) {
        setKKCookie(CookieName.LoggedIn, 1, 7);
        setKKCookie(CookieName.WidgetSignedIn, authenticatedUser, 7);
      }
      hideSignupForm();
    });
    if (onSuccessCallback in window) {
      window[onSuccessCallback](data);
    }
    window.dispatchEvent(
      new CustomEvent('kk-auth-widget-success', {
        detail: data
      })
    );
  }

  function showErrorMessage(msg) {
    showSnackbar(msg, 'error', 5000);
  }

  const handleSocialSignIn = (provider) => async () => {
    currentProvider = provider;
    try {
      showLoading = true;
      const userCredentials = await AuthService.oAuthSignInUsingPopup(provider);
      const user = userCredentials.user ?? AuthService.getSignedInUser();
      if (!user) {
        showErrorMessage(
          `Unable to authenticate with ${provider.chatAt(0).toUpperCase().concat(provider.substr(1))}. Invalid response received. Please try again`
        );
        return;
      }
      const idToken = await user.getIdToken();
      if (user.email) {
        if (!user.emailVerified) {
          await AuthService.sendEmailVerification();
          verificationInProgress = true;
          authenticatedUser = user.email;
          await generateCustomToken(idToken, window.location.href).catch(() => null);
          setKKCookie(CookieName.WidgetSignedIn, user.email, 7);
          await trackSignUp(user).catch(() => null);
          await AuthService.signOut().catch(() => null);
          return;
        }
        username = user.displayName;
        await createSecureUserSession(await user.getIdToken()).catch(() => null);
        await trackSignIn(user).catch(() => null);
        await AuthService.signOut().catch(() => null);
        authenticatedUser = user.email;
        showAccountCreatedSuccessMessage();
      }
    } catch (err) {
      const firebaseError = err;
      if (
        err.code === AuthErrorCode.AccountExistsWithDifferentProvider ||
        err.code === AuthErrorCode.EmailInUse
      ) {
        showErrorMessage(AuthErrorMessageMap[AuthErrorCode.EmailInUse]);
        return;
      }
      if (AuthErrorMessageMap[err.code]) {
        showErrorMessage(AuthErrorMessageMap[err.code]);
        return;
      }
      showErrorMessage(AuthErrorMessageMap[AuthErrorCode.Unhandled]);
    } finally {
      showLoading = false;
    }
  };

  function resetTurnstile() {
    turnstileToken = '';
    window.turnstile.reset();
  }

  async function createAccountUsingEmailAndPassword(e) {
    currentProvider = OAuthProviderNames.Password;
    try {
      function resetForm() {
        e.target.elements.name.value = '';
        e.target.elements.email.value = '';
        e.target.elements.password.value = '';
      }

      try {
        const token = turnstileToken ?? window?.turnstile?.getResponse();
        if (!token) {
          throw 'Invalid token';
        }
        const turnstileResponse = await fetch(
          `${ADMIN_SDK_SERVICE_ENDPOINT}/turnstile/verify-token`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({token})
          }
        ).then((res) => res.json);
        resetTurnstile();
        console.log({turnstileResponse});
      } catch (err) {
        console.log({err});
        showErrorMessage('Captcha verification failed.');
        return;
      }
      showLoading = true;
      const name = e.target.elements.name.value;
      const email = e.target.elements.email.value;
      const password = e.target.elements.password.value;
      try {
        const userCredentials = await AuthService.createUserWithEmailAndPassword(email, password);
        const user = userCredentials.user ?? AuthService.getSignedInUser();
        await AuthService.updateProfile(user, {displayName: name});
        const idToken = await user.getIdToken();
        username = name;
        if (!user.emailVerified) {
          await AuthService.sendEmailVerification();
          verificationInProgress = true;
          authenticatedUser = user.email;
          await generateCustomToken(idToken, window.location.href).catch(() => null);
          setKKCookie(CookieName.WidgetSignedIn, user.email, 7);
          await trackSignUp(user).catch(() => null);
          await AuthService.signOut().catch(() => null);
          return;
        }
        await createSecureUserSession(idToken).catch(() => null);
        await trackSignIn(user).catch(() => null);
        await AuthService.signOut().catch(() => null);
        authenticatedUser = userCredentials.user.email;
        authenticatedUser = email;
        setKKCookie(CookieName.WidgetSignedIn, email, 7);
        showAccountCreatedSuccessMessage();
        resetForm();
      } catch (err) {
        if (err.code === AuthErrorCode.EmailNotVerified) {
          await AuthService.sendEmailVerification();
          verificationInProgress = true;
          return;
        }
        if (err.code === AuthErrorCode.EmailInUse) {
          showErrorMessage(AuthErrorMessageMap[AuthErrorCode.EmailInUse]);
          resetForm();
          return;
        }
        showErrorMessage(AuthErrorMessageMap[AuthErrorCode.Unhandled]);
        return;
      }
    } finally {
      showLoading = false;
    }
  }
</script>

<TailwindCss/>

<main
        style="display: flex; flex-direction: column; justify-content: center; align-items: center;"
        class="relative bg-white p-8 rounded-[20px]"
>
    {#if authCheckInProgress && authCheckPromise}
        {#await authCheckPromise}
            <Loader/>
        {/await}
    {:else if !authCheckInProgress && !verificationInProgress && authenticatedUser}
        <Loader/>
    {:else}
        <section class="flex flex-1 flex-grow flex-col items-center justify-center box-content w-full">
            {#if verificationInProgress}
                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="48" viewBox="0 0 24 24"
                >
                    <path
                            fill="rgb(29 172 254 / 100% )"
                            d="M4 20q-.825 0-1.412-.587T2 18V6q0-.825.588-1.412T4 4h16q.825 0 1.413.588T22 6v12q0 .825-.587 1.413T20 20zm8-7.175q.125 0 .263-.038t.262-.112L19.6 8.25q.2-.125.3-.312t.1-.413q0-.5-.425-.75T18.7 6.8L12 11L5.3 6.8q-.45-.275-.875-.012T4 7.525q0 .25.1.438t.3.287l7.075 4.425q.125.075.263.113t.262.037"
                    ></path>
                </svg
                >
                <h1 class="mt-8 text-3xl text-primary-100 md:whitespace-nowrap">
                    Please confirm your account
                </h1>
                <p class="mt-3 gap-x-1 font-callout text-base text-secondary-80">
                    <span>Check your email:</span>
                    <span class="bg-gradient-primary-light bg-clip-text">
						{authenticatedUser}
					</span>
                    <span>and click the activation link</span>
                </p>
            {/if}
            {#if !verificationInProgress}
                <div class="auth-form flex flex-col gap-4 items-center justify-center w-full">
                    <div class="text-3xl">Create your free account</div>
                    <Form
                            {createAccountUsingEmailAndPassword}
                            {showLoading}
                            {turnstileToken}
                            {handleTurnstileToken}
                    />
                    <div
                            class="bg-red relative mx-auto flex w-full max-w-xs items-center justify-center mt-2"
                    >
                        <div class="h-px w-full bg-gray-light-100"></div>
                        <p class="absolute bg-white px-4 text-sm text-tertiary-40">or</p>
                    </div>
                    <div class="flex flex-wrap gap-4 justify-center gap-x-2.5 w-fit">
                        <SocialSignInButton
                                provider={OAuthProviderNames.Google}
                                on:click={handleSocialSignIn(OAuthProviderNames.Google)}
                        />
                        <SocialSignInButton
                                provider={OAuthProviderNames.Github}
                                on:click={handleSocialSignIn(OAuthProviderNames.Github)}
                        />
                        <SocialSignInButton
                                provider={OAuthProviderNames.Facebook}
                                on:click={handleSocialSignIn(OAuthProviderNames.Facebook)}
                        />
                        <SocialSignInButton
                                provider={OAuthProviderNames.Microsoft}
                                on:click={handleSocialSignIn(OAuthProviderNames.Microsoft)}
                        />
                        <SocialSignInButton
                                provider={OAuthProviderNames.Linkedin}
                                on:click={handleSocialSignIn(OAuthProviderNames.Linkedin)}
                        />
                    </div>
                </div>
            {/if}
        </section>
    {/if}
    {#if showLoading}
        <Loader/>
    {/if}
</main>

<style lang="scss">
  main:not(.social-sign-in-button) {
    font-family: Inter, system-ui, sans-serif !important;
  }

  .toast-container-wrapper {
    z-index: 100000 !important;
  }

  :global(.toast-container-wrapper) {
    .toast-container li {
      background-color: #fff !important;
    }
  }
</style>
