<div
	class="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] z-10 h-full flex flex-col items-center justify-center"
>
	<div
		class="flex flex-1 flex-grow flex-col items-center justify-center w-full md:w-82.5 box-content"
	>
		<svg xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" viewBox="0 0 24 24"
			><circle cx="12" cy="2" r="0" fill="#1cacfe"
				><animate
					attributeName="r"
					begin="0"
					calcMode="spline"
					dur="1s"
					keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
					repeatCount="indefinite"
					values="0;2;0;0"
				/></circle
			><circle cx="12" cy="2" r="0" fill="#1cacfe" transform="rotate(45 12 12)"
				><animate
					attributeName="r"
					begin="0.125s"
					calcMode="spline"
					dur="1s"
					keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
					repeatCount="indefinite"
					values="0;2;0;0"
				/></circle
			><circle cx="12" cy="2" r="0" fill="#1cacfe" transform="rotate(90 12 12)"
				><animate
					attributeName="r"
					begin="0.25s"
					calcMode="spline"
					dur="1s"
					keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
					repeatCount="indefinite"
					values="0;2;0;0"
				/></circle
			><circle cx="12" cy="2" r="0" fill="#1cacfe" transform="rotate(135 12 12)"
				><animate
					attributeName="r"
					begin="0.375s"
					calcMode="spline"
					dur="1s"
					keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
					repeatCount="indefinite"
					values="0;2;0;0"
				/></circle
			><circle cx="12" cy="2" r="0" fill="#1cacfe" transform="rotate(180 12 12)"
				><animate
					attributeName="r"
					begin="0.5s"
					calcMode="spline"
					dur="1s"
					keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
					repeatCount="indefinite"
					values="0;2;0;0"
				/></circle
			><circle cx="12" cy="2" r="0" fill="#1cacfe" transform="rotate(225 12 12)"
				><animate
					attributeName="r"
					begin="0.625s"
					calcMode="spline"
					dur="1s"
					keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
					repeatCount="indefinite"
					values="0;2;0;0"
				/></circle
			><circle cx="12" cy="2" r="0" fill="#1cacfe" transform="rotate(270 12 12)"
				><animate
					attributeName="r"
					begin="0.75s"
					calcMode="spline"
					dur="1s"
					keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
					repeatCount="indefinite"
					values="0;2;0;0"
				/></circle
			><circle cx="12" cy="2" r="0" fill="#1cacfe" transform="rotate(315 12 12)"
				><animate
					attributeName="r"
					begin="0.875s"
					calcMode="spline"
					dur="1s"
					keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
					repeatCount="indefinite"
					values="0;2;0;0"
				/></circle
			></svg
		>
	</div>
</div>
