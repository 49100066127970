<script>
</script>

<div class="relative block rounded-1.5xl bg-black-4 outline-none w-full">
	<input
		id="password"
		class="peer block w-full rounded-xl border-none bg-transparent pb-2.5 pl-4 pr-10 pt-6 outline-offset-2 placeholder:text-transparent focus-within:outline-dotted focus-within:outline-1 focus-within:outline-blue-80"
		type="password"
		name="password"
		placeholder="Enter password"
		minlength="6"
		required
		on:keyup
		on:input
	/>
	<label
		class="absolute left-4 flex -translate-y-1/2 transform text-secondary-80 duration-200 peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-base peer-placeholder-shown:text-secondary-80 peer-focus:top-4 peer-focus:text-xs peer-focus:text-blue-100 top-4 text-xs !font-normal"
		for="password"
	>
		Enter password
	</label>
</div>

<style>
</style>
