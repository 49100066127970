export var AuthErrorCode;
(function (AuthErrorCode) {
    AuthErrorCode["EmailInUse"] = "auth/email-already-in-use";
    AuthErrorCode["AccountExistsWithDifferentProvider"] = "auth/account-exists-with-different-credential";
    AuthErrorCode["WrongPassword"] = "auth/wrong-password";
    AuthErrorCode["AccountNotFound"] = "auth/user-not-found";
    AuthErrorCode["EmailInUseLink"] = "auth/email-already-in-use/link";
    AuthErrorCode["SignInEmailLinkExpired"] = "auth/invalid-action-code";
    AuthErrorCode["EmailNotVerified"] = "auth/email-not-verified";
    AuthErrorCode["TooManyRequests"] = "auth/too-many-requests";
    AuthErrorCode["InvalidLoginCredentials"] = "auth/invalid-login-credentials";
    AuthErrorCode["NetworkRequestFailed"] = "auth/network-request-failed";
    AuthErrorCode["InvalidCredentials"] = "auth/invalid-credential";
    AuthErrorCode["RequiresRecentLogin"] = "auth/requires-recent-login";
    AuthErrorCode["InvalidResultFromGoogle"] = "auth/invalid-result-from-google";
    AuthErrorCode["InvalidSSOMethod"] = "auth/invalid-sso-method";
    AuthErrorCode["InvalidSSOSignInEmail"] = "auth/invalid-sso-sign-in-email";
    AuthErrorCode["Unhandled"] = "auth/unhandled";
})(AuthErrorCode || (AuthErrorCode = {}));
export const AuthErrorMessageMap = {
    [AuthErrorCode.WrongPassword]: 'The email or password you entered is incorrect.',
    [AuthErrorCode.EmailInUse]: 'This email already exist or is linked to an account. Please use a different email address or choose another sign-in option.',
    [AuthErrorCode.AccountNotFound]: 'Incorrect email or password. Please try again.',
    [AuthErrorCode.AccountExistsWithDifferentProvider]: 'This email is already in use or linked to an existing account. Please use a different email address or choose another sign-in option.',
    [AuthErrorCode.EmailInUseLink]: 'Account exists for that email with different provider, please use different email.',
    [AuthErrorCode.SignInEmailLinkExpired]: 'Your verification link has expired and can only be used once. Please request a new one before trying again.',
    [AuthErrorCode.EmailNotVerified]: 'Your account has not been verified yet. Please verify your account before logging in',
    [AuthErrorCode.TooManyRequests]: 'You have attempted to request for the verification email too many times. Please wait a moment and try again',
    [AuthErrorCode.InvalidLoginCredentials]: 'Incorrect email or password. Please try again.',
    [AuthErrorCode.InvalidCredentials]: 'Incorrect email or password. Please try again.',
    [AuthErrorCode.NetworkRequestFailed]: 'There seems to be a problem connecting to the server. Please try again later.',
    [AuthErrorCode.RequiresRecentLogin]: 'Please log in again to proceed. A recent login is required to change your password for security reasons.',
    [AuthErrorCode.InvalidSSOMethod]: 'There was an error with this SSO sign-in method. Please try again or use another login option',
    [AuthErrorCode.InvalidResultFromGoogle]: 'Something went wrong while getting result from Google. Please Try again.',
    [AuthErrorCode.Unhandled]: 'Something went wrong. Please try again or contact support.'
};
export const TURNSTILE_SCRIPT_URL = 'https://challenges.cloudflare.com/turnstile/v0/api.js?onload=onloadTurnstileCallback';
export const CookieName = {
    LoggedIn: 'kk_logged_in',
    WidgetSignedIn: 'kk-auth-widget-signed-in-user-email',
    WidgetSignupPage: 'kk-auth-widget-signup-page',
};
