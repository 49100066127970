import {} from './types.js';
export const ADMIN_SDK_SERVICE_ENDPOINT = 'https://identity-api.kodekloud.com/api';
export const checkAuthStatus = () => {
    return fetch(ADMIN_SDK_SERVICE_ENDPOINT.concat('/check-auth-status'), {
        method: 'post',
        credentials: 'include'
    }).then(async (res) => {
        if (res.status === 200) {
            return (await res.json());
        }
        return null;
    }).catch((e) => {
        console.error(e);
    });
};
export const createSecureUserSession = (idToken) => {
    return fetch(ADMIN_SDK_SERVICE_ENDPOINT.concat('/create-user-session'), {
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ token: idToken }),
        credentials: 'include'
    });
};
export const generateCustomToken = (idToken, callbackUrl) => {
    return fetch(ADMIN_SDK_SERVICE_ENDPOINT.concat('/generate-custom-token'), {
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ token: idToken, callbackUrl }),
        credentials: 'include'
    });
};
